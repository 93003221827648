import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, styled } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import useStaticNavigationQuery from '@hooks/useStaticNavigationQuery'
import StrapiImage from '@templates/strapi-cms/content-types/Image'
import ListItem from './ListItem'
import DrawerToolbar from './DrawerToolbar'

const Wrapper = styled('div')(({ theme, categoryMenuIsVisible }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxHeight: categoryMenuIsVisible ? '100%' : '100vh',
  overflowY: categoryMenuIsVisible ? 'auto' : 'hidden',
  paddingBottom: '16px',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  fontWeight: 400,
  textTransform: 'none',
  justifyContent: 'flex-start',
  '& .MuiSvgIcon-root ': {
    fontSize: '24px',
    color: theme.palette.primary.main,
  },
  height: '42px',
  minHeight: '42px',
  margin: '0 5px',
  '&:hover': {
    backgroundColor: '#FFF',
  },
}))

const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '10px 15px',
  borderBottom: '1px solid rgba(0, 83, 160, 0.04)',
  borderTop: '1px solid rgba(0, 83, 160, 0.04)',
  alignItems: 'center',
  height: '100px',
}))

const CategoryTitle = styled('div')(({ theme }) => ({
  fontSize: '23px',
  fontWeight: 400,
  textTransform: 'uppercase',
  marginLeft: '15px',
  color: '#333',
}))

const CategoryImgWrapper = styled('div')(({ theme }) => ({
  height: '80px',
  width: '80px',
  minWidth: '80px',
  minHeight: '80px',
  aspectRatio: '1 / 1',
  borderRadius: '50%',
  overflow: 'hidden',
  backgroundColor: '#F6F6F6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const CategoryImg = styled(StrapiImage)(({ theme }) => ({
  height: '80px',
  width: '80px',
  padding: '10px',
}))

export const generateImgData = imageObj => ({
  desktop: {
    url: `${imageObj?.url}?h=160`,
  },
  mobile: {
    url: `${imageObj?.url}?h=160`,
  },
  alt: imageObj?.alternativeText,
  imageSizing: 'contain',
})

const CategoryMenu = ({ selectedIndex, onDrawerClose, setSelectedIndex, transitionDuration }) => {
  const [localIndex, setLocalIndex] = useState(null)
  const navigation = useStaticNavigationQuery()
  const { HeaderNavLinks } = navigation?.[localIndex] || {}
  const primaryLink = HeaderNavLinks?.PrimaryLink?.Link
  const categoryMenuIsVisible = typeof selectedIndex === 'number'

  // when returning to categories, this prevents the current category display from removing until after
  // slide transition has ended
  useEffect(() => {
    let timeout
    if (!categoryMenuIsVisible) {
      timeout = setTimeout(() => {
        setLocalIndex(null)
      }, [transitionDuration])
    } else if (categoryMenuIsVisible) {
      setLocalIndex(selectedIndex)
      clearTimeout(timeout)
    }
    return clearTimeout(timeout)
  }, [selectedIndex, categoryMenuIsVisible, transitionDuration])

  return (
    <Wrapper categoryMenuIsVisible={categoryMenuIsVisible}>
      <DrawerToolbar onDrawerClose={onDrawerClose} />
      <StyledButton size="small" startIcon={<ChevronLeft />} onClick={() => setSelectedIndex(null)}>
        Back To Categories
      </StyledButton>
      <TitleWrapper>
        {HeaderNavLinks?.MobileCategoryImage && (
          <CategoryImgWrapper>
            <CategoryImg data={generateImgData(HeaderNavLinks?.MobileCategoryImage)} noPaddingBottom height={80} />
          </CategoryImgWrapper>
        )}
        <CategoryTitle>{primaryLink?.MobileDisplayText ?? primaryLink?.DisplayText}</CategoryTitle>
      </TitleWrapper>
      <ListItem
        onDrawerClose={onDrawerClose}
        data={HeaderNavLinks}
        categoryMenuIsVisible={categoryMenuIsVisible}
        transitionDuration={transitionDuration}
      />
    </Wrapper>
  )
}

CategoryMenu.propTypes = {
  selectedIndex: PropTypes.number,
  onDrawerClose: PropTypes.func,
  setSelectedIndex: PropTypes.func,
  transitionDuration: PropTypes.number,
}

export default CategoryMenu
