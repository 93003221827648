import React from 'react'
import { Button, styled } from '@mui/material'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import ListOutlinedIcon from '@mui/icons-material/ListOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'
import { useTest } from '@hooks/useTest'

const FooterWrapper = styled('div')(({ theme }) => ({
  padding: '15px',
  backgroundColor: 'rgb(244, 247, 250)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#333333',
  fontSize: '14px',
  textTransform: 'none',
  '& .MuiSvgIcon-root ': {
    fontSize: '20px',
  },
  height: '28px',
  margin: '5px 0',
}))

const DrawerFooter = () => {
  const { generateTestId } = useTest()

  return (
    <FooterWrapper>
      <StyledButton
        size="small"
        startIcon={<FavoriteBorderOutlinedIcon />}
        component={ButtonLink}
        data={{ InternalUrl: '/favorites', testId: generateTestId('navigation footer', 'favorites') }}
        trackingData={{ category: 'mobile meganav', action: 'click', label: 'favorites icon' }}
      >
        Favorites
      </StyledButton>
      <StyledButton
        size="small"
        startIcon={<ShoppingCartCheckoutIcon />}
        component={ButtonLink}
        data={{ InternalUrl: '/store-to-cart-input', testId: generateTestId('navigation footer', 'my store cart') }}
        trackingData={{ category: 'mobile meganav', action: 'click', label: 'store cart icon' }}
      >
        My Store Cart
      </StyledButton>
      <StyledButton
        size="small"
        startIcon={<ListOutlinedIcon />}
        component={ButtonLink}
        data={{ InternalUrl: '/order/status', testId: generateTestId('navigation footer', 'order status') }}
        trackingData={{ category: 'mobile meganav', action: 'click', label: 'order status icon' }}
      >
        Order Status
      </StyledButton>
      <StyledButton
        size="small"
        startIcon={<PaidOutlinedIcon />}
        component={ButtonLink}
        data={{ InternalUrl: '/order/status', testId: generateTestId('navigation footer', 'pay order balance') }}
        trackingData={{ category: 'mobile meganav', action: 'click', label: 'pay order balance icon' }}
      >
        Pay Order Balance
      </StyledButton>
    </FooterWrapper>
  )
}

export default DrawerFooter
