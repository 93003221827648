import { useRef, useEffect } from 'react'
import _isEqual from 'lodash/isEqual'

// eslint-disable-next-line import/prefer-default-export
export const usePrevious = (value, onlyUpdateIfDifferent = false) => {
  const ref = useRef()
  useEffect(() => {
    if (!onlyUpdateIfDifferent || !_isEqual(ref.current, value)) ref.current = value
  })

  return ref.current
}
