import React from 'react'
import { styled, Typography } from '@mui/material'
import { getRegionZone } from '@helpers/geo-location'
import { suggestionPassEvent } from '@helpers/google-tag-manager'
import { number, object, string } from 'prop-types'

const CategoryWrapper = styled('a')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: '15px 15px 0 0',
  width: '25%',
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '152px',
    justifyContent: 'center',
  },
}))

const StyledImgWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#f6f6f6',
  display: 'flex',
  justifyContent: 'center',
  height: '110px',
  width: '100%',
  '& img': {
    padding: '10%',
    width: 'auto',
    height: '100%',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    '& img': {
      boxSizing: 'border-box',
    },
  },
}))

const CategoryTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.primary.dark,
  fontWeight: 500,
  paddingTop: '5px',
}))

const ConnectedCategory = ({ category = {}, index, query }) => {
  const { region } = getRegionZone()

  return (
    <CategoryWrapper
      href={category.route}
      data-index={`categorySuggest${index}`}
      tabIndex={0}
      onClick={() =>
        suggestionPassEvent({
          query,
          suggested: true,
          suggestion: category?.displayName,
          index,
          isCategory: true,
        })
      }
      role="option"
      onKeyDown={event =>
        event.key === 'Enter' &&
        suggestionPassEvent({
          query,
          suggested: true,
          suggestion: category?.displayName,
          index,
          isCategory: true,
        })
      }
    >
      <StyledImgWrapper>
        <img src={category?.image?.[region]} alt={category?.displayName} />
      </StyledImgWrapper>
      <CategoryTitle>{category?.displayName}</CategoryTitle>
    </CategoryWrapper>
  )
}

ConnectedCategory.propTypes = {
  category: object,
  index: number,
  query: string,
}

export default ConnectedCategory
