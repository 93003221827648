import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getFinancePlans } from '@helpers/finance'
import { makeStyles } from '@material-ui/core'
import { get } from 'lodash'
import InfoModal from '@shared/info-modal'

const useStyles = makeStyles(theme => ({
  html: {
    padding: theme.spacing(2),
  },
}))

const LearnMoreModal = ({ defaultOpened = false }) => {
  const classes = useStyles()
  const highestPriorityFinancePlan = getFinancePlans()?.[0]
  const [showFinanceModal, setShowFinanceModal] = useState(defaultOpened)
  const modalHtml = get(highestPriorityFinancePlan, 'promoMessage.childMarkdownRemark.html', null)

  const handleClose = () => {
    setShowFinanceModal(false)
  }

  useEffect(() => {
    document.addEventListener('click', e => {
      if (
        e.target.classList.contains('synchrony-prequal-learn-more') ||
        e.target.classList.contains('synchrony-prequal-learn-more-cart')
      ) {
        e.preventDefault()
        e.stopPropagation()
        setShowFinanceModal(true)
      }
    })
  }, [])

  return (
    showFinanceModal &&
    modalHtml && (
      <InfoModal
        label="Rooms To Go Credit Options"
        mdlClass="rtg-credit-modal"
        shouldShowModal={showFinanceModal}
        closeModal={() => handleClose()}
      >
        <div
          className={classes.html}
          dangerouslySetInnerHTML={{
            __html: modalHtml,
          }}
        />
      </InfoModal>
    )
  )
}

LearnMoreModal.propTypes = {
  defaultOpened: PropTypes.bool,
}

export default LearnMoreModal
