import { createContext, useContext } from 'react'

const ServerSideLocation = createContext({
  region: '',
  price_zone: '',
  zip: '',
  disableFinanceBanner: false,
})

export const ServerSideLocationProvider = ServerSideLocation.Provider

export const useServerSideLocation = () => useContext(ServerSideLocation) || {}
