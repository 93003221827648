import React from 'react'
import classNames from 'classnames'
import { string, any } from 'prop-types'
import { styled } from '@mui/material'
import SearchBox from '../searchv2/search-box'
import '../../assets/css/components/shared/search-form.sass'

const SearchContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: '6px 0 6px 0',
  },
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  '& > section': {
    width: '100%',
  },
}))

const SearchForm = ({ id, addClasses, mobileHitsCount, viewName }) => (
  <SearchContainer key={id} className={classNames(addClasses)}>
    <SearchBox mobileHitsCount={mobileHitsCount} viewName={viewName} />
  </SearchContainer>
)

SearchForm.propTypes = {
  id: string,
  addClasses: any,
  mobileHitsCount: any,
  viewName: string,
}

export default SearchForm
