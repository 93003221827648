// dependencies
import React, { useState, forwardRef } from 'react'
import { string, bool } from 'prop-types'
import { Collapse, Toolbar } from '@mui/material'
import { useTest } from '@hooks/useTest'

// components
import SearchForm from '../../../../../components/shared/search-form'

const MobileSearch = forwardRef(({ className, showSearch }, ref) => {
  const { generateTestId } = useTest()
  return (
    <Collapse
      ref={ref}
      className={className}
      in={showSearch}
      unmountOnExit
      data-testid={generateTestId('collapse-search', showSearch ? 'show' : 'hide')}
    >
      <Toolbar>
        <SearchForm
          viewName="mobile-header"
          id="search-mobile cell"
          addClasses={{
            searchmobile: true,
            'mobile-only': true,
          }}
          mobileHitsCount
        />
      </Toolbar>
    </Collapse>
  )
})

MobileSearch.displayName = 'MobileSearch'

MobileSearch.propTypes = {
  showSearch: bool,
  className: string,
}

export default MobileSearch
