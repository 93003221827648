import { styled, Button, IconButton } from '@mui/material'

export const NavButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'keepText',
})(({ theme, keepText }) => ({
  textTransform: 'unset',
  marginLeft: theme.spacing(2),
  textUnderlineOffset: '2px',
  fontSize: '0.875rem',

  '.text-wrapper': {
    display: 'block',
    textAlign: 'left',
    color: theme.palette.primary.dark,
  },

  '.btn-text': {
    display: 'block',
    lineHeight: '1.2rem',
  },

  '.underline': {
    textDecoration: 'underline',
  },

  svg: {
    color: theme.palette.primary.dark,
  },

  '.MuiButton-endIcon': {
    marginTop: '1.25rem', // 20px
    marginLeft: '0.188rem', // 3px
  },

  [theme.breakpoints.down('lg')]: {
    ...(!keepText && {
      '.text-wrapper .btn-text': {
        display: 'none',
      },
    }),

    '.MuiButton-startIcon': {
      marginRight: 0,
    },

    '.MuiButton-endIcon': {
      marginTop: '1.063rem', // 17px
      marginRight: '-0.188rem', // 3px
    },
  },
}))

export const HeaderIconBtn = styled(IconButton)(({ theme }) => ({
  svg: {
    color: theme.palette.primary.dark,
  },
  [theme.breakpoints.down('lg')]: {
    '& > span': {
      display: 'none',
    },
  },
}))
