import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { object } from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { storeGrowthbookModalInLs, addGrowthbookTag } from '@components/GrowthbookComponent'

const StrapiPersonalizedModal = ({ data }) => {
  const modalData = data?.PersonalizedModal ? data.PersonalizedModal : data
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { FeatureId } = modalData
  const { HTML, CSS, Script } = modalData.HTML?.HTML ?? {}
  const modalFeatureIsOn = useFeatureIsOn(FeatureId)

  useEffect(() => {
    const modalTag = `${FeatureId}-seen`
    const personalizedModalTag = 'personalized-modal-seen'
    if (modalFeatureIsOn && FeatureId) {
      setModalIsOpen(true)
      storeGrowthbookModalInLs(modalTag)
    }
    return () => {
      if (modalFeatureIsOn && FeatureId) {
        addGrowthbookTag(modalTag)
        addGrowthbookTag(personalizedModalTag)
        setModalIsOpen(false)
      }
    }
  }, [modalFeatureIsOn, FeatureId])

  const renderModal = () => (
    <div>
      {CSS && <Helmet style={[{ type: 'text/css', cssText: CSS }]} />}
      {Script && <Helmet script={[{ type: 'text/javascript', innerHTML: Script }]} />}
      {HTML && <div dangerouslySetInnerHTML={{ __html: HTML }} />}
    </div>
  )

  return <>{modalIsOpen && renderModal()}</>
}

export default StrapiPersonalizedModal

StrapiPersonalizedModal.propTypes = {
  data: object.isRequired,
}

export const personalizedModalFragment = graphql`
  fragment personalizedModalFragment on StrapiPersonalizedModal {
    Title
    FeatureId
    id
    HTML {
      HTML {
        id
        CSS
        Script
        HTML
        DisplaySettings
      }
    }
  }
`

export const PersonalizedModalGlobal = () => {
  const data = useStaticQuery(PersonalizedModalGlobalQuery)
  const { nodes } = data?.allStrapiPersonalizedModal

  if (!nodes?.length) return <></>

  return (
    <>
      {nodes.map(modalData => (
        <StrapiPersonalizedModal data={modalData} key={modalData?.id} />
      ))}
    </>
  )
}

const PersonalizedModalGlobalQuery = graphql`
  query {
    allStrapiPersonalizedModal(filter: { GlobalModal: { eq: true } }) {
      nodes {
        ...personalizedModalFragment
      }
    }
  }
`
