import React from 'react'

import PropTypes from 'prop-types'
import { Collapse, List, styled, ListItemButton } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import { useTest } from '@hooks/useTest'

const ButtonItem = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
  borderBottom: '1px solid rgba(0, 83, 160, 0.04)',
  padding: '10px 15px',
  height: '48px',
  '& a': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '.MuiTouchRipple-child': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: '#FFF',
  },
}))

const ButtonText = styled('div')(({ theme, textColor }) => ({
  display: 'flex',
  color: textColor ?? theme.palette.primary.dark,
  fontSize: '16px',
  fontWeight: 500,
  padding: '2px 0',
  textTransform: 'normal',
}))

const SubButtonText = styled('div')(({ theme, textColor }) => ({
  display: 'flex',
  color: textColor ?? theme.palette.primary.dark,
  fontSize: '16px',
  fontWeight: 400,
  padding: '0 15px',
  minWidth: '50%',
  textTransform: 'normal',
}))

const SubItem = ({ data, onDrawerClose, openSubItem, setOpenSubItem }) => {
  const HeadingLink = data?.LinkList?.HeadingLink?.Link
  const Links = data?.LinkList?.Links || []
  const isExpandable = Links.length > 0
  const HeadingLinkClickable = data?.LinkList?.HeadingLinkClickable
  const LinkListId = data?.LinkList?.id
  const open = openSubItem === LinkListId
  const { generateTestId } = useTest()

  const onLinkClick = e => {
    e.stopPropagation()
    onDrawerClose()
  }

  const onExpand = e => {
    e.stopPropagation()
    setOpenSubItem(open ? null : LinkListId)
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        category: 'navigation',
        action: 'open menu',
        label: HeadingLink?.DisplayText,
      })
    }
  }

  const renderSubButtons = () => (
    <List component="div" sx={{ padding: 0 }}>
      {Links?.map(item => {
        const link = item?.Link
        return (
          <StrapiLink
            key={link?.id}
            data-testid={link?.id}
            data={link}
            trackingData={{
              category: 'navigation',
              action: 'link click',
              label: link?.DisplayText,
            }}
            onClick={onLinkClick}
          >
            <ButtonItem data-testid={generateTestId('navigation', 'sub-sub-category')}>
              <SubButtonText textColor={link?.Color?.ColorHex}>
                {link?.MobileDisplayText ?? link?.DisplayText}
              </SubButtonText>
            </ButtonItem>
          </StrapiLink>
        )
      })}
    </List>
  )

  if (HeadingLinkClickable && !isExpandable) {
    return (
      <StrapiLink
        data-testid={HeadingLink?.id}
        data={HeadingLink}
        trackingData={{
          category: 'navigation',
          action: 'link click',
          label: HeadingLink?.DisplayText,
        }}
        onClick={onLinkClick}
      >
        <ButtonItem data-testid={generateTestId('navigation', 'sub-category-link')}>
          <ButtonText textColor={HeadingLink?.Color?.ColorHex}>{HeadingLink?.DisplayText}</ButtonText>
        </ButtonItem>
      </StrapiLink>
    )
  }

  return (
    <>
      <ButtonItem
        onClick={isExpandable ? onExpand : () => {}}
        data-testid={generateTestId('navigation', 'sub-category')}
      >
        <ButtonText textColor={HeadingLink?.Color?.ColorHex}>{HeadingLink?.DisplayText}</ButtonText>
        {isExpandable && (open ? <ExpandLess /> : <ExpandMore />)}
      </ButtonItem>
      {isExpandable && (
        <Collapse in={open} timeout="auto">
          {renderSubButtons()}
        </Collapse>
      )}
    </>
  )
}

SubItem.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  openSubItem: PropTypes.string,
  setOpenSubItem: PropTypes.func,
}

export default SubItem
