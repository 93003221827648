import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  border-radius: 50%;
  width: 10em;
  height: 10em;

  margin-left: 7px;
  font-size: 0.15rem;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #0053a0;
  border-right: 1.1em solid #0053a0;
  border-bottom: 1.1em solid #0053a0;
  border-left: 1.1em solid white;
  transform: translateZ(0);
  animation: ${rotate} 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
`

export default Spinner
