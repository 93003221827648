import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import smoothscroll from 'smoothscroll-polyfill'
import { ApolloProvider } from '@apollo/client'
import { abbreviateState } from '@helpers/geo-location'
import { getLineItemQuantity } from '@helpers/cart'
import { getFromBrowserStorage } from '@helpers/storage'
import { sentryLogger } from '@helpers/sentry-logger'
import { setShippingAddress, setLocation, setStateExclusions } from '@redux/modules/location'
import { setCart } from '@redux/modules/cart'
import { setDeliveryMode, setOrder } from '@redux/modules/checkout'
import { getStateExclusions } from '@services/state-exclusions'
import GrowthbookComponent from '@components/GrowthbookComponent'
import { PersonalizedModalGlobal } from '@templates/strapi-cms/content-types/PersonalizedModal'
import { Buffer } from 'buffer/'
import { debounce } from 'lodash'
import Layout from './layout'
import apolloClient from '../../gatsby-plugin-apollo/client'

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
  window.Buffer = Buffer
}

class LayoutWrapper extends Component {
  state = {
    scrolled: false,
  }

  componentDidMount() {
    const cookies = new Cookies()
    const userId = getFromBrowserStorage('local', 'uuid')
    const {
      deliveryMode,
      rtg_location,
      order,
      shipping_address,
      isLandscape,
      onSetDeliveryMode,
      onSetLocation,
      onSetOrder,
      onSetShippingAddress,
    } = this.props

    this.removeUnusedCookies(cookies)

    if (process.env.NODE_ENV === 'development') {
      if (!cookies.get('__Host-rtgp')) {
        cookies.set('__Host-rtgp', 'e5d49640-5af6-11e9-b882-6197050ecff1', {
          path: '/',
          secure: true,
          sameSite: 'lax',
        })
      }
      if (!cookies.get('__Host-TL_RTG')) {
        cookies.set('__Host-TL_RTG', 'c823fdec-3136-ed38-8477-601659e5cb70', {
          path: '/',
          secure: true,
          sameSite: 'lax',
        })
      }
    }

    sentryLogger({
      init: {
        dsn: process.env.GATSBY_SENTRY_DNS_URL,
        environment: process.env.GATSBY_ENV_SHORT,
        release: process.env.GATSBY_RELEASE_VERSION,
      },
      configureScope: {
        type: 'setUser',
        variableExists: userId,
        id: userId,
      },
    })

    if (isLandscape) {
      window.addEventListener('scroll', this.updateScrollPosition)
    }
    const changeLocRefresh = getFromBrowserStorage('local', 'changeLocReturn')
    if (changeLocRefresh) {
      try {
        document.getElementById(changeLocRefresh).focus()
        localStorage.removeItem('changeLocReturn')
      } catch (err) {}
    }

    const rtg_location_cookie = cookies.get('__Host-rtg_location')
    if (rtg_location_cookie) {
      const shippingAddress = `${rtg_location_cookie.city}, ${abbreviateState(rtg_location_cookie.state)}`
      if (shippingAddress !== shipping_address) {
        onSetShippingAddress(`${rtg_location_cookie.city}, ${abbreviateState(rtg_location_cookie.state)}`)
      }
    }
    if (rtg_location_cookie && rtg_location && rtg_location.zip !== rtg_location_cookie.zip) {
      onSetLocation(rtg_location_cookie)
    }
    if (process.env.NODE_ENV === 'development' && !rtg_location_cookie) {
      onSetLocation()
    }
    this.setCartFromLocalStorage()
    if (window) {
      window.addEventListener('storage', () => {
        debounce(this.setCartFromLocalStorage, 100)()
      })
    }
    const orderStorage = getFromBrowserStorage('session', 'order')

    if (orderStorage && orderStorage.orderId && JSON.stringify(order) !== JSON.stringify(orderStorage)) {
      onSetOrder(orderStorage)

      // set deliveryMode if a store cart was assigned as pickup type or has an assigned delivery date
      // unless the order has split delivery
      if (
        orderStorage?.lineItems?.some(item => item?.isStoreSku) &&
        orderStorage?.storeCart &&
        !deliveryMode &&
        !order.splitCalendar
      ) {
        if (orderStorage.isPickup) {
          onSetDeliveryMode('pickup')
        } else {
          const hasStoreCartDeliveryDate = orderStorage?.storeCart?.lineItems.some(i => i.deliveryDate)
          if (hasStoreCartDeliveryDate) {
            onSetDeliveryMode(orderStorage?.doorwayDelivery ? 'doorway' : 'delivery')
          }
        }
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const { isLandscape, shipping_address, onLoadStateExclusions } = this.props
    if (prevProps.isLandscape !== isLandscape && isLandscape) {
      window.addEventListener('scroll', this.updateScrollPosition)
    } else if (prevProps.isLandscape !== isLandscape && !isLandscape) {
      window.removeEventListener('scroll', this.updateScrollPosition)
    }
    const currentShippingStateAbbreviation = shipping_address.slice(-2)
    if (prevProps.shipping_address.slice(-2) !== currentShippingStateAbbreviation) {
      const exclusions = await getStateExclusions(currentShippingStateAbbreviation)
      onLoadStateExclusions(exclusions)
    }
  }

  shouldComponentUpdate(nextProps) {
    const { cart, order } = this.props
    if (nextProps.cart !== cart || nextProps.order !== order) {
      return false
    }
    return true
  }

  removeUnusedCookies(cookies) {
    cookies.remove('rtg_distribution_index')
    cookies.remove('rtg_location')
    // cookies.remove('rtg_region')
    cookies.remove('TL_RTG')
    cookies.remove('rtg_zip')
    cookies.remove('session_id')
  }

  updateScrollPosition = () => {
    const { scrolled } = this.state
    if (window.scrollY > 150 && !scrolled) {
      this.setState({
        scrolled: true,
      })
    } else if (window.scrollY < 150 && scrolled) {
      this.setState({
        scrolled: false,
      })
    }
  }

  setCartFromLocalStorage = () => {
    const { cart, onSetCart } = this.props
    const cartStorage = getFromBrowserStorage('local', 'cart')
    if (
      cartStorage &&
      cartStorage.cartItems &&
      JSON.stringify(cart.cartItems) !== JSON.stringify(cartStorage.cartItems)
    ) {
      onSetCart(cartStorage)
    }
  }

  render() {
    const { order, isProductPage, className } = this.props
    const cartQuantity = getLineItemQuantity(order).cart
    return (
      <ApolloProvider client={apolloClient}>
        <GrowthbookComponent>
          <PersonalizedModalGlobal />
          <Layout data={this.props} cartQuantity={cartQuantity} isProductPage={isProductPage} className={className} />
        </GrowthbookComponent>
      </ApolloProvider>
    )
  }
}

LayoutWrapper.propTypes = {
  cart: PropTypes.shape({ cartItems: PropTypes.any }),
  className: PropTypes.string,
  deliveryMode: PropTypes.string,
  isLandscape: PropTypes.any,
  isProductPage: PropTypes.bool,
  onLoadStateExclusions: PropTypes.func,
  onSetCart: PropTypes.func,
  onSetDeliveryMode: PropTypes.func,
  onSetLocation: PropTypes.func,
  onSetOrder: PropTypes.func,
  onSetShippingAddress: PropTypes.func,
  order: PropTypes.any,
  rtg_location: PropTypes.shape({ zip: PropTypes.any }),
  shipping_address: PropTypes.any,
}

LayoutWrapper.defaultProps = {
  isProductPage: false,
}

LayoutWrapper.defaultProps = {
  isProductPage: false,
}

const mapStateToProps = state => ({
  deliveryMode: state.checkout.deliveryMode,
  isLandscape: state.global.isLandscape,
  isMobile: state.global.isMobile,
  rtg_location: state.location.rtg_location,
  shipping_address: state.location.shipping_address,
  showSearchResults: state.global.showSearchResults,
  order: state.checkout.order,
  cart: state.cart.cart,
})

const mapDispatchToProps = dispatch => ({
  onSetShippingAddress: shipping_address => dispatch(setShippingAddress(shipping_address)),
  onSetLocation: location => dispatch(setLocation(location)),
  onSetCart: cart => dispatch(setCart(cart)),
  onSetDeliveryMode: mode => dispatch(setDeliveryMode(mode)),
  onSetOrder: order => dispatch(setOrder(order)),
  onLoadStateExclusions: exclusions => dispatch(setStateExclusions(exclusions)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutWrapper)
