import React from 'react'
import { navigate } from 'gatsby'
import { styled, Box, Typography, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { useUserQuery } from '@hooks/auth'
import { useModalsContext } from '@context/modalContext'
import { useFirebaseContext } from '@context/firebaseContext'

const OuterWrapper = styled(Box)`
  padding: 0 10px;
  background-color: #fff;
`

const Wrapper = styled(Box)`
  background-color: rgb(244, 247, 250);
  padding: 6px 15px;
  border-radius: 4px;

  p,
  a {
    font-size: 14px;
    line-height: 14px;
  }
`

const createOnClickHandler = (name, callback = () => {}) => () => {
  addToDataLayer_nextgen('account_link_click', {
    account_link_name: name,
    account_link_location: 'header',
  })
  callback()
}

const MyAccountMenu = ({ onDrawerClose }) => {
  const { data: userQuery } = useUserQuery()
  const { user } = useFirebaseContext()
  const { actions } = useModalsContext()

  const onAccountClick = () => {
    if (user) {
      onDrawerClose()
      navigate('/account/profile')
    } else {
      onDrawerClose()
      actions.login()
    }
  }

  return (
    <OuterWrapper>
      <Wrapper>
        {user ? (
          <Typography variant="body2">Welcome, {userQuery?.contact?.firstName}</Typography>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">Welcome</Typography>
            <Button
              onClick={createOnClickHandler('Log In', onAccountClick)}
              size="medium"
              sx={{
                textDecoration: 'underline',
                textTransform: 'none',
                color: 'text.primary !important',
                fontSize: '0.9rem',
                '&:hover': {
                  backgroundColor: 'unset',
                },
              }}
            >
              Login or Create Account
            </Button>
          </Box>
        )}
      </Wrapper>
    </OuterWrapper>
  )
}

MyAccountMenu.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
}

export default MyAccountMenu
