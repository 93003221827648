import { useState, useMemo, useEffect } from 'react'

// eslint-disable-next-line import/prefer-default-export
export function useIsInViewport(targetRef) {
  const [isIntersecting, setIsIntersecting] = useState(null)

  const observer = useMemo(() => {
    if (typeof window !== 'undefined')
      return new window.IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), {})

    return null
  }, [])

  useEffect(() => {
    if (targetRef) {
      observer?.observe(targetRef)
    }

    return () => {
      observer?.disconnect()
    }
  }, [targetRef, observer])

  return isIntersecting
}
