// eslint-disable-next-line import/prefer-default-export
import { theme as rtgTheme } from '@rtgdev/design-system'
import { responsiveFontSizes, createTheme } from '@material-ui/core'

// Map regionId to RTG location. RegionId comes from google when user clicks google ads to land on PDP, regionId will show up as
// a parameter in the url
export const regionToZipCode = {
  '001000': {
    region: 'FL',
    zone: '0',
    zipCode: 33603,
  },
  '001001': {
    region: 'FL',
    zone: '1',
    zipCode: 33603,
  },
  '001002': {
    region: 'FL',
    zone: '2',
    zipCode: 33603,
  },
  '001003': {
    region: 'FL',
    zone: '3',
    zipCode: 32114,
  },
  '001004': {
    region: 'FL',
    zone: '4',
    zipCode: 33417,
  },
  '001005': {
    region: 'FL',
    zone: '5',
    zipCode: 33603,
  },
  '001006': {
    region: 'FL',
    zone: '6',
    zipCode: 70114,
  },
  '002000': {
    region: 'SE',
    zone: '0',
    zipCode: 30332,
  },
  '002001': {
    region: 'SE',
    zone: '1',
    zipCode: 30332,
  },
  '002002': {
    region: 'SE',
    zone: '2',
    zipCode: 23453,
  },
  '002003': {
    region: 'SE',
    zone: '3',
    zipCode: 28203,
  },
  '002004': {
    region: 'SE',
    zone: '4',
    zipCode: 30332,
  },
  '002005': {
    region: 'SE',
    zone: '5',
    zipCode: 30332,
  },
  '002006': {
    region: 'SE',
    zone: '6',
    zipCode: 30332,
  },
  '003000': {
    region: 'TX',
    zone: '0',
    zipCode: 77009,
  },
  '003001': {
    region: 'TX',
    zone: '1',
    zipCode: 77009,
  },
  '003002': {
    region: 'TX',
    zone: '2',
    zipCode: 77009,
  },
  '003003': {
    region: 'TX',
    zone: '3',
    zipCode: 77009,
  },
  '003004': {
    region: 'TX',
    zone: '4',
    zipCode: 75062,
  },
  '003005': {
    region: 'TX',
    zone: '5',
    zipCode: 78746,
  },
  '003006': {
    region: 'TX',
    zone: '6',
    zipCode: 78207,
  },
  '004000': {
    region: 'PR',
    zone: '0',
  },
  '004001': {
    region: 'PR',
    zone: '1',
  },
  '004002': {
    region: 'PR',
    zone: '2',
  },
  '004003': {
    region: 'PR',
    zone: '3',
  },
  '004004': {
    region: 'PR',
    zone: '4',
  },
  '004005': {
    region: 'PR',
    zone: '5',
  },
  '004006': {
    region: 'PR',
    zone: '6',
  },
}

export const pageTheme = responsiveFontSizes(
  createTheme({
    ...rtgTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 639,
        md: 1024,
        lg: 1480,
        xl: 1920,
      },
    },
  }),
)
