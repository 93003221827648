import axios from 'axios'
import { expandState } from '@helpers/geo-location'

// eslint-disable-next-line import/prefer-default-export
export const getStateExclusions = async stateAbbreviation => {
  const stateName = expandState(stateAbbreviation).toLowerCase()
  if (!stateName || !process.env.GATSBY_STATE_EXCLUSIONS_URL) return []
  const res = await axios.get(`${process.env.GATSBY_STATE_EXCLUSIONS_URL}${stateName}`)
  const isResponseValid =
    res?.data?.response &&
    typeof res.data.response.state === 'string' &&
    res.data.response.state.toLowerCase() === stateName &&
    Array.isArray(res.data.response.exclusions)
  if (!isResponseValid) return []
  const exclusions = []
  res.data.response.exclusions.forEach(collectionName => {
    if (collectionName && typeof collectionName === 'string') exclusions.push(collectionName.toLowerCase())
  })
  return exclusions
}
