import React, { useState, useEffect } from 'react'
import { Drawer, styled, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import useIsFirstRender from '@hooks/useIsFirstRender'
import DrawerToolbar from './DrawerToolbar'
import MyAccountMenu from './MyAccountMenu'
import CategoriesMenu from './CategoriesMenu'
import CategoryMenu from './CategoryMenu'
import DrawerFooter from './DrawerFooter'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: 1202,
}))

const Wrapper = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  width: '90vw',
  maxWidth: '450px',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'flex',
  '& > div': {
    width: '100%',
    flexShrink: 0,
  },
  '& a:focus': {
    outline: 'none !important',
  },
  [`@media (max-width: 380px)`]: {
    width: '100vw',
  },
}))

const MainMenuWrapper = styled('div')(({ categoryIsOpen, transitionDuration, transtionDelay }) => ({
  transition: `margin-left ${transitionDuration - 200}ms ease`,
  transitionDelay: `${transtionDelay}ms`,
  marginLeft: categoryIsOpen ? '-100%' : '0%',
  minHeight: '100vh',
  backgroundColor: 'rgb(244, 247, 250)',
}))

const transitionDuration = 550
const transitionDelay = 200

const MobileDrawer = ({ open, onDrawerClose }) => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const isFirstRender = useIsFirstRender()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const categoryIsOpen = typeof selectedIndex === 'number'

  useEffect(() => {
    if (!open) {
      setSelectedIndex(null)
    }
  }, [open])

  return (
    <StyledDrawer
      anchor="left"
      open={open && isMobile}
      onClose={onDrawerClose}
      ModalProps={{
        'aria-label': 'Main Menu',
        'aria-describedby': 'Browse products using the categories in this menu',
        keepMounted: true,
        disablePortal: isFirstRender,
      }}
    >
      <Wrapper>
        <MainMenuWrapper
          categoryIsOpen={categoryIsOpen}
          transitionDuration={transitionDuration}
          transtionDelay={transitionDelay}
        >
          <DrawerToolbar onDrawerClose={onDrawerClose} />
          <MyAccountMenu onDrawerClose={onDrawerClose} />
          <CategoriesMenu setSelectedIndex={setSelectedIndex} onDrawerClose={onDrawerClose} />
          <DrawerFooter />
        </MainMenuWrapper>
        <CategoryMenu
          selectedIndex={selectedIndex}
          onDrawerClose={onDrawerClose}
          setSelectedIndex={setSelectedIndex}
          transitionDuration={transitionDuration}
        />
      </Wrapper>
    </StyledDrawer>
  )
}

MobileDrawer.propTypes = {
  open: PropTypes.bool,
  onDrawerClose: PropTypes.func,
}

export default MobileDrawer
