import React, { useEffect, useState, useCallback } from 'react'
import { styled } from '@mui/material'

const BalloonContainerStyles = styled('div')(({ theme }) => ({
  height: '100vh',
  padding: '1em',
  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'hidden',
  transition: 'opacity 500ms',
  position: 'absolute',
  [`@media (max-width: 1660px)`]: {
    display: 'none',
  },
}))

const BalloonStyles = styled('div')(() => ({
  height: '125px',
  width: '105px',
  borderRadius: '75% 75% 70% 70%',
  position: 'relative',
  '&:before': {
    content: '""',
    height: '75px',
    width: '1px',
    padding: '1px',
    backgroundColor: '#FDFD96',
    display: '',
    position: 'absolute',
    top: '125px',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  '&:after': {
    content: '"▲"',
    textAlign: 'center',
    display: 'block',
    position: 'absolute',
    color: 'inherit',
    top: '120px',
    left: 0,
    right: 0,
    margin: 'auto',
  },

  '@keyframes float': {
    '0%': {
      transform: 'translateY(100vh)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(-300vh)',
      opacity: 0,
    },
  },
}))

function BalloonContainer() {
  const [isVisible, setIsVisible] = useState(true)
  const random = num => Math.floor(Math.random() * num)

  const getRandomStyles = useCallback(() => {
    const r = random(255)
    const g = random(255)
    const b = random(255)
    const mt = random(200)
    const ml = random(50)
    const dur = random(5) + 10
    return {
      backgroundColor: `rgba(${r},${g},${b},0.7)`,
      color: `rgba(${r},${g},${b},0.7)`,
      boxShadow: `inset -7px -3px 10px rgba(${r - 10},${g - 10},${b - 10},0.7)`,
      margin: `${mt}px 0 0 ${ml}px`,
      animation: `float ${dur}s ease-in infinite`,
    }
  }, [])

  const createBalloons = useCallback(
    num => {
      const balloons = []
      for (let i = 0; i < num; i++) {
        balloons.push(<BalloonStyles className="balloon" style={getRandomStyles()} key={i} />)
      }
      return balloons
    },
    [getRandomStyles],
  )

  useEffect(() => {
    createBalloons(300)

    const timer = setTimeout(() => {
      setIsVisible(false)
    }, 30000)

    const handleScroll = () => {
      setIsVisible(false)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      clearTimeout(timer)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [createBalloons])

  return (
    isVisible && <BalloonContainerStyles className="balloon-container">{createBalloons(200)}</BalloonContainerStyles>
  )
}

export default BalloonContainer
