import React, { useEffect, useRef } from 'react'
import { oneOfType, string, number, object, func, node, bool } from 'prop-types'

const ClickOutsideWrapper = ({ children, callback, toggle, analyticsCB, analyticsData }) => {
  const wrapperRef = useRef()

  useEffect(() => {
    const handleClick = e => {
      if (wrapperRef.current.contains(e.target)) return callback(true)
      if (typeof analyticsCB === 'function') analyticsCB(analyticsData, true)
      return callback(false)
    }
    if (toggle) {
      document.addEventListener('mousedown', handleClick)
    }
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [analyticsCB, analyticsData, callback, toggle])

  return <section ref={wrapperRef}>{children}</section>
}

ClickOutsideWrapper.propTypes = {
  children: node,
  callback: func,
  toggle: bool,
  analyticsCB: func,
  analyticsData: oneOfType([string, number, object]),
}

export default ClickOutsideWrapper
