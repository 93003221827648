import React, { useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { Grid, Button, Stack, Typography, IconButton, Link as MuiLink, styled, css } from '@mui/material'
import { setOrder } from '@redux/modules/checkout'
import CloseIcon from '@mui/icons-material/Close'
import NavigateNext from '@mui/icons-material/NavigateNext'
import classNames from 'classnames'

import {
  getCartTotal,
  getStoreCartTotal,
  removeFromCart,
  verifyAndUpdateCart,
  getProductPriceWithSelections,
} from '@helpers/cart'
import { currencyFormatUS } from '@helpers/string-helper'
import { isProductStrikeThrough, productPrice } from '@helpers/product'
import { getRegionZone } from '@helpers/geo-location'
import { productUrl } from '@helpers/route'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'
import PriceAtom from '../../@rtg2022/components/atoms/Price/Price'
import CouponTag from '../../@rtg2022/components/atoms/CouponTag/CouponTag'

export const getProperlySizedImageUrl = url => {
  if (url) {
    const searchParam = 'h=50'
    const seperator = url.includes('?') ? '&' : '?'
    return `${url}${seperator}${searchParam}`
  }
  return ''
}

const GridWrapper = styled(Grid)(
  ({ theme }) => css`
    .strikethrough {
      font-size: 0.75rem;
      line-height: 0.75rem;
    }

    .strikethrough-sale,
    .highlight-price {
      color: ${theme.palette.error.main};
    }
  `,
)

const StyledPromoLabel = styled('span')(({ theme }) => ({
  padding: '4px',
  backgroundColor: theme.palette.error.main,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '.5rem',
  borderRadius: '4px',
  color: theme.palette.common.white,
  position: 'absolute',
  alignSelf: 'flex-start',
  top: '-5px',
  left: '-8px',
}))

const StyledDiscountPrice = styled(PriceAtom)(() => ({
  fontSize: '.7rem',
}))

export const CartPopout = () => {
  const { cart } = useSelector(state => state.cart)
  const { order } = useSelector(state => state.checkout)
  const dispatch = useDispatch()
  const { region } = getRegionZone()

  const handleRemoveFromCart = (product, index, uid) => {
    removeFromCart(product, index, uid)
    if (order) dispatch(setOrder({ ...order, promotions: null }))
  }

  const cartTotal = useMemo(() => {
    let total = getCartTotal(cart)
    if (order && order.lineItems) {
      const storeCartLineItems = order.lineItems.filter(lineItem => lineItem.isStoreSku)
      if (storeCartLineItems.length > 0) {
        total += getStoreCartTotal(storeCartLineItems)
      }
    }
    return total
  }, [cart, order])

  const cartItemsSkus = useMemo(() => cart.cartItems.map(cartItem => cartItem.sku), [cart])
  const orderItemsSkus = useMemo(() => order?.promotions?.lineItems.map(item => item.sku), [order])

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Typography id="cartSubtotal" color="error" variant="subtitle1" textAlign="center">
          Subtotal:{' '}
          <b>
            <PriceAtom price={currencyFormatUS(cartTotal)} />
          </b>
        </Typography>
      </Grid>

      <Grid item sm={12}>
        <Button
          component={ButtonLink}
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          data={{
            InternalUrl: '/checkout',
            Title: 'Go to Checkout',
          }}
        >
          Checkout
        </Button>
      </Grid>
      <Grid item sm={12}>
        <Button
          size="small"
          component={ButtonLink}
          data={{
            InternalUrl: '/cart',
            Title: 'View Full Cart',
            DisplayText: 'View Full Cart',
          }}
          fullWidth
          trackingData={{ category: 'header', action: 'cart interaction', label: 'view full cart' }}
          endIcon={<NavigateNext />}
        >
          View Full Cart
        </Button>
      </Grid>
      {(!cart || (cart?.cartItems && cart.cartItems.length === 0)) && (
        <Grid item sm={12}>
          <Typography textAlign="center" variant="body2">
            Your shopping cart is empty
          </Typography>
        </Grid>
      )}
      {cart?.cartItems &&
        cart.cartItems.length > 0 &&
        cart.cartItems.map((item, index) => {
          const { product, quantity, sku, uid, promotion } = item
          const { isConfigurable: isConfiguratorItem = false } = product
          let route = null
          if (product && !isConfiguratorItem && product?.route) route = product?.route

          const promoInfo =
            order?.promotions?.lineItems?.find(
              promo =>
                promo?.sku === item?.sku &&
                promo?.bonusItemSavings &&
                promo?.promotionStatus === 'APPLIED' &&
                orderItemsSkus.includes(promo?.promotionItemSku),
            ) || (cartItemsSkus.includes(promotion?.promotionItemSku) ? promotion : null)

          const strikethrough = isProductStrikeThrough(product)
          const additionalOptionLineItem = order?.lineItems?.find(
            lineItem => lineItem?.sku === sku && lineItem?.additionalOption,
          )
          const additionalOptionSavings =
            additionalOptionLineItem?.additionalOptionPrice?.savings || item?.additionalOptionPrice?.savings || 0
          const savings = promoInfo?.bonusItemSavings || additionalOptionSavings || 0
          const fullPrice = getProductPriceWithSelections(productPrice(product), quantity, item, savings)

          const couponApplied =
            (!!item.product?.couponDisplayData || !!item.product?.couponDisplayFields?.[`${region}`]) &&
            (cart.cartItems.some(_cartItem => _cartItem?.promotion?.promotionItemSku === item.sku) ||
              !item.product.promotions)

          return (
            <Grid container item sm={12} key={uid || sku} spacing={1}>
              <hr />
              <Grid container item sm={11} spacing={1}>
                <Grid item sm={4}>
                  {isConfiguratorItem ? (
                    <Stack justifyContent="center">
                      <img
                        style={{
                          height: '80px',
                          width: 'auto',
                          margin: '0 auto',
                        }}
                        src={getProperlySizedImageUrl(product?.primary_image || product?.image)}
                        alt={product?.title}
                        role="presentation"
                      />
                    </Stack>
                  ) : (
                    <MuiLink
                      component={ButtonLink}
                      data={{
                        InternalUrl: productUrl(route),
                      }}
                      aria-hidden="true"
                      sx={t => ({
                        display: 'flex',
                        justifyContent: 'center',
                        border: 'solid 2px transparent',
                        '&:focus': {
                          border: `solid 2px ${t.palette.primary.main}`,
                        },
                      })}
                      disabled={product?.category === 'gift-card'}
                    >
                      <img
                        style={{
                          height: '60px',
                          width: 'auto',
                        }}
                        src={getProperlySizedImageUrl(product?.primary_image || product?.image)}
                        alt={product?.title}
                        role="presentation"
                      />
                      {promoInfo && <StyledPromoLabel>BONUS BUY INCLUDED</StyledPromoLabel>}
                    </MuiLink>
                  )}
                </Grid>

                <Grid container item sm={8}>
                  {isConfiguratorItem ? (
                    <Grid item sm={12}>
                      <p style={{ fontSize: '0.9rem' }}>{product?.title}</p>
                    </Grid>
                  ) : (
                    <Grid item sm={12}>
                      {couponApplied && <CouponTag />}
                      <MuiLink
                        component={ButtonLink}
                        data={{
                          InternalUrl: productUrl(route),
                        }}
                        sx={{
                          fontSize: '0.9rem',
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                          '&:focus': {
                            textDecoration: 'underline',
                          },
                        }}
                        aria-describedby={`mcq_${index}mcp_${index}`}
                        disabled={product?.category === 'gift-card'}
                      >
                        <span dangerouslySetInnerHTML={{ __html: product?.displayNamePDP || product?.title }} />
                      </MuiLink>
                    </Grid>
                  )}

                  <Grid item sm={6}>
                    <Typography sx={{ fontSize: '0.8rem' }} variant="body2">
                      Qty: {quantity}
                    </Typography>
                  </Grid>

                  <GridWrapper item sm={6}>
                    {strikethrough && (
                      <Typography variant="body2" sx={{ fontSize: '0.8rem' }} className="strikethrough">
                        <PriceAtom strikeThrough price={currencyFormatUS(productPrice(product, true))} />
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={{ fontSize: '0.8rem' }}
                      className={classNames(
                        strikethrough ? 'strikethrough-sale' : '',
                        product?.on_sale || !!promoInfo?.bonusItemSavings ? 'highlight-price' : '',
                      )}
                    >
                      <PriceAtom price={currencyFormatUS(fullPrice)} />
                    </Typography>
                  </GridWrapper>
                </Grid>
              </Grid>

              <Grid item sm={1}>
                <IconButton
                  title="Remove from cart"
                  size="small"
                  onClick={() => handleRemoveFromCart(product, index, uid)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          )
        })}
    </Grid>
  )
}

export default CartPopout
