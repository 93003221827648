import React from 'react'
import PropTypes from 'prop-types'
import { Typography, styled, Box } from '@mui/material'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'

const CouponWrapper = styled('div')({
  display: 'flex',
  height: '24px',
  width: '79px',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  color: 'white',
  fontSize: '13px',
  backgroundColor: '#00854A',
  padding: '4px 8px',
  borderRadius: '.75rem 0.125rem .75rem .75rem',
})

const MainWrapper = styled(Box)(({ source, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  marginTop: '8px',
  [theme.breakpoints.down('md')]:
    source === 'cart' ? { flexDirection: 'column', alignItems: 'flex-start', gap: '2px' } : {},
}))

const CouponTag = ({ source = 'miniCart', message, PriceComponent }) => (
  <MainWrapper source={source}>
    <CouponWrapper>
      <LocalOfferOutlinedIcon fontSize="inherit" />
      <Typography color="white" textTransform="uppercase" fontSize="10px" fontWeight="600">
        Coupon
      </Typography>
    </CouponWrapper>
    {source === 'cart' && (
      <>
        <Typography fontSize="13px" variant="body1" component="p" fontWeight="400" textTransform="none" color="#07263b">
          {message ? <>{message}</> : <>Coupon applied for special offer {PriceComponent}</>}
        </Typography>
      </>
    )}
  </MainWrapper>
)
CouponTag.propTypes = {
  source: PropTypes.string,
  PriceComponent: PropTypes.object,
  message: PropTypes.string,
}

export default CouponTag
