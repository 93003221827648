import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, Dialog, styled } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import RTGLogo from '@shared/svgs/logo'

const EmailUnsubscribeModal = () => {
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    textAlign: 'center',
  }))

  const StyledCloseIcon = styled(CloseOutlinedIcon)`
    position: absolute;
    right: 0;
    margin: 5px;
    cursor: pointer;
  `

  const Container = styled('div')`
    text-align: center;
    height: 100%;
  `
  const ConfirmationMessage = styled(Typography)(({ theme }) => ({
    marginLeft: '5px',
    lineHeight: '1.2',
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette.success.dark,
  }))

  const ConfirmationContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      margin: 'auto',
    },
  }))

  const SubHeader = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      margin: 'auto',
    },
  }))

  const CloseButton = styled(Button)`
    margin: 60px 0;
    width: 150px;
  `

  return (
    <StyledDialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          width: '600px',
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
        },
      }}
    >
      <Container>
        <StyledCloseIcon sx={{ color: '#808080' }} onClick={handleClose} />
        <div style={{ padding: '80px 0 60px 0' }}>
          <RTGLogo uniqueNameForId="unsubscribe" width="255px" />
        </div>
        <ConfirmationContainer>
          <CheckCircleOutlineIcon color="success" />
          <ConfirmationMessage>Email Unsubscribed Successfully</ConfirmationMessage>
        </ConfirmationContainer>

        <SubHeader>You will no longer be solicited for product reviews.</SubHeader>
        <CloseButton onClick={handleClose} variant="contained">
          Close
        </CloseButton>
      </Container>
    </StyledDialog>
  )
}

export default EmailUnsubscribeModal
