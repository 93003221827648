import React from 'react'

import PropTypes from 'prop-types'
import { Grid, Box, styled } from '@mui/material'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import { useTest } from '@hooks/useTest'
import RightSideBanners from './RightSideBanners'

const GridWrapper = styled('div')`
  display: grid;
  grid-template-columns: 38.1% 61.9%;
  gap: 0;
  margin: 20px;
  .link {
    color: #333;
    text-decoration: none;
    display: block;
    line-height: normal;
    padding-bottom: 5px;
  }

  .clickable {
    &:hover,
    &:focus {
      color: ${p => p.theme.palette.primary.main};
      text-decoration: underline;
      outline: none !important;
    }
    & .link-arrow-next {
      top: 46%;
    }
  }

  .subcategory {
    font-size: 14px;
    line-height: 18px;
  }

  .heading {
    font-size: 16px;
    font-weight: 600;
    width: fit-content;
  }
`

const LeftSide = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
`

const Megamenu = ({ data, onClose }) => {
  const navLinks = data?.NavLinks
  const bannerGroup = data?.LeftSideCategoryBanners?.NavigationBannerGroup
  const { generateTestId } = useTest()

  /* per Janis, use auto column flow for living room category only -- everything else uses balance so the content is spread evenly */
  /* FD-2818 - fix for broken auto in Chrome and Firefo */
  // const columnFill = Object.is(data?.PrimaryLink?.Link?.DisplayText.toLowerCase(), 'living rooms') ? 'auto' : 'balance'
  const columnFill = data?.NavLinksSingleColumn ? 'auto' : 'balance'

  const columnCount = bannerGroup ? 2 : 5

  return (
    <GridWrapper>
      <LeftSide sx={{ gridColumn: bannerGroup ? '1 / 2' : '1 / 3', gridRow: '1 / 2' }}>
        <Box
          style={{
            columns: `${columnCount}`,
            columnGap: 20,
            paddingRight: 40,
            columnFill,
            width: '100%',
            maxHeight: data?.NavLinksSingleColumn ? '530px' : 'auto',
          }}
        >
          {navLinks?.map?.(({ LinkList }, index) => (
            <Box key={LinkList?.id} style={{ paddingBottom: 14, breakInside: 'avoid' }}>
              {LinkList?.HeadingLinkClickable ? (
                <StrapiLink
                  className="link heading clickable"
                  data-textid={LinkList?.HeadingLink?.Link?.id}
                  data={LinkList?.HeadingLink}
                  onClick={onClose}
                  arrowNext
                  trackingData={{
                    category: 'navigation',
                    action: 'link click',
                    label: LinkList?.HeadingLink?.Link?.Title || LinkList?.HeadingLink?.Link?.DisplayText,
                  }}
                />
              ) : (
                <div
                  className="heading link"
                  style={{ color: LinkList?.HeadingLink?.Link?.Color?.ColorHex ?? '#333' }}
                  data-testid={generateTestId('navigation', 'sub-category')}
                >
                  {LinkList?.HeadingLink?.Link?.DisplayText}
                </div>
              )}

              {LinkList?.Links?.map?.(({ Link }) => (
                <StrapiLink
                  key={Link?.id}
                  data-textid={Link?.id}
                  className="link subcategory clickable"
                  data={Link}
                  onClick={onClose}
                  trackingData={{
                    category: 'navigation',
                    action: 'link click',
                    label: Link?.Title || Link?.DisplayText,
                  }}
                />
              ))}
            </Box>
          ))}
        </Box>
      </LeftSide>
      <RightSideBanners bannerGroup={bannerGroup} onClose={onClose} />
    </GridWrapper>
  )
}

Megamenu.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Megamenu
