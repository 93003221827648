import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { List } from '@mui/material'
import SubItem from './SubItem'

const ListItem = ({ onDrawerClose, data = null, categoryMenuIsVisible, transitionDuration }) => {
  const [openSubItem, setOpenSubItem] = useState(null)
  const NavLinks = data?.NavLinksMobile?.length ? data.NavLinksMobile : data?.NavLinks

  // closes category accordions when category menu is exited; waits after slide transition period
  useEffect(() => {
    let timeout
    if (!categoryMenuIsVisible) {
      timeout = setTimeout(() => {
        setOpenSubItem(null)
      }, transitionDuration)
    } else {
      clearTimeout(timeout)
    }
    return () => clearTimeout(timeout)
  }, [categoryMenuIsVisible, transitionDuration])

  return (
    <List component="div" style={{ padding: 0 }}>
      {NavLinks?.map(subNav => (
        <SubItem
          data={subNav}
          onDrawerClose={onDrawerClose}
          key={subNav?.LinkList?.id}
          openSubItem={openSubItem}
          setOpenSubItem={setOpenSubItem}
        />
      ))}
    </List>
  )
}

ListItem.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  categoryMenuIsVisible: PropTypes.bool,
  transitionDuration: PropTypes.number,
}

export default ListItem
