// dependencies
import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { styled, Fade, useMediaQuery } from '@mui/material'
import { validateCouponFromLocalStorage } from '@services/coupon'
// redux
import { setIsMobile as setIsMobileAction, setIsLandscape as setIsLandscapeAction } from '@redux/modules/global'
// helpers
import { isMobileWindow, isLandscapeWindow } from '@helpers/deviceWindow'
// hooks
import { useUserQuery } from '@hooks/auth'
import { useFirebaseContext } from '@context/firebaseContext'

// components
import DesktopHeader from './desktop/AppBar'
import MobileHeader from './mobile/AppBar'

const StyledHeader = styled('div')`
  .mobile-only {
    ${p => p.theme.breakpoints.up('md')} {
      display: none;
    }
  }
  .desktop-only {
    ${p => p.theme.breakpoints.down('md')} {
      display: none;
    }
  }
`

const Backdrop = styled('div')`
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  width: 100vw;
  height: 100vh;
  overflow: overflow;
  position: fixed;
  top: 0;
  left: 0;
`

const Header = ({ checkout = false }) => {
  const [open, setOpen] = useState(false)
  const [shouldDisplayMobileHeader, setShouldDisplayMobileHeader] = useState(true)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const onSetBackdrop = (value = false) => setOpen(value)

  const { data } = useUserQuery()
  const { user } = useFirebaseContext()

  const updateWindowDimensions = useCallback(() => {
    dispatch(setIsMobileAction(isMobileWindow()))
    dispatch(setIsLandscapeAction(isLandscapeWindow()))
  }, [dispatch])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowDimensions()
      window.addEventListener('resize', updateWindowDimensions)
      return () => window.removeEventListener('resize', updateWindowDimensions)
    }
    return () => {}
  }, [updateWindowDimensions])

  useLayoutEffect(() => {
    setShouldDisplayMobileHeader(isMobile)
  }, [isMobile])

  useEffect(() => {
    validateCouponFromLocalStorage()
  }, [])

  return (
    <>
      <Fade in={!checkout && open} timeout={400} unmountOnExit>
        <Backdrop />
      </Fade>
      <StyledHeader>
        <DesktopHeader isCheckout={checkout} onSetBackdrop={onSetBackdrop} authData={{ data, user }} />
        {shouldDisplayMobileHeader && <MobileHeader isCheckout={checkout} />}
      </StyledHeader>
    </>
  )
}

Header.propTypes = {
  checkout: PropTypes.bool,
}

export default Header
