import React from 'react'
import { Toolbar, styled, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import RTGLogo from '@shared/svgs/logo'
import { LogoWrapper } from '@components/header/mobile/Styled'

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: '50px',
  backgroundColor: '#FFF',
  '& #mobile-logo-header-drawer svg': {
    maxWidth: '175px',
  },
}))

const CloseIconWrapper = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '8px',
  color: theme.palette.primary.dark,
}))

const DrawerToolbar = ({ onDrawerClose }) => (
  <StyledToolbar>
    <LogoWrapper
      id="mobile-logo-header-drawer"
      data={{
        InternalUrl: '/',
        Title: 'Rooms To Go Home',
      }}
      trackingData={{ category: 'header', action: 'logo click', label: 'logo' }}
    >
      <RTGLogo uniqueNameForId="mobile-header-drawer" />
    </LogoWrapper>
    <CloseIconWrapper aria-label="close mobile menu" onClick={onDrawerClose}>
      <CloseIcon />
    </CloseIconWrapper>
  </StyledToolbar>
)

DrawerToolbar.propTypes = {
  onDrawerClose: PropTypes.func,
}

export default DrawerToolbar
