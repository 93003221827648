import React from 'react'
import PropTypes from 'prop-types'
import { List as MuiList, styled, ListItemButton, ListItem } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import StrapiImage from '@templates/strapi-cms/content-types/Image'
import useStaticNavigationQuery from '@hooks/useStaticNavigationQuery'
import { useTest } from '@hooks/useTest'
import StrapiLink from '@templates/strapi-cms/content-types/Link'

const CategoriesMenuWrapper = styled(MuiList)(({ theme }) => ({
  width: '100%',
  padding: '0',
  backgroundColor: '#FFF',
  borderBottom: '1px solid rgba(0, 83, 160, 0.08)',
}))

const MenuItem = styled(ListItem)(({ theme }) => ({
  height: '60px',
  padding: '0',
  borderBottom: '1px solid rgba(0, 83, 160, 0.04)',
  '&:last-child': {
    borderBottom: 'none',
  },
}))

const MenuItemButton = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '60px',
  padding: '0 15px',
  '&:hover': {
    backgroundColor: '#FFF',
  },
  '& .MuiTouchRipple-child': {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}))

const MenuItemTitle = styled('div')(({ theme }) => ({
  marginLeft: '15px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: theme.palette.primary.dark,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  lineClamp: '2',
  WebkitBoxOrient: 'vertical',
}))

const MenuItemImgWrapper = styled('div')(() => ({
  height: '50px',
  width: '50px',
  minWidth: '50px',
}))

const StyledChevron = styled(ChevronRightIcon)(({ theme }) => ({
  height: '20px',
  width: '20px',
  color: theme.palette.primary.dark,
  marginRight: '4px',
  marginLeft: 'auto',
}))

export const generateImgData = imageObj => ({
  desktop: {
    url: `${imageObj?.url}?h=320`,
  },
  mobile: {
    url: `${imageObj?.url}?h=320`,
  },
  alt: imageObj?.alternativeText,
  imageSizing: 'contain',
})

const CategoriesMenu = ({ setSelectedIndex, onDrawerClose }) => {
  const navigation = useStaticNavigationQuery()
  const { generateTestId } = useTest()

  const handleClick = (index, hasChildren) => {
    if (hasChildren) {
      setSelectedIndex(index)
    } else {
      onDrawerClose()
    }
  }

  return (
    <CategoriesMenuWrapper component="nav" disablePadding>
      {navigation.map(({ HeaderNavLinks }, index) => {
        const hasChildren = !!HeaderNavLinks?.NavLinks?.length || !!HeaderNavLinks?.NavLinksMobile?.length
        const contentID = `mobile-menuitem-${HeaderNavLinks?.id}`
        const primaryLink = HeaderNavLinks?.PrimaryLink?.Link

        return (
          <MenuItem key={contentID} disablePadding disableGutters>
            <MenuItemButton
              onClick={() => handleClick(index, hasChildren)}
              disableGutters
              data-testid={generateTestId('navigation', 'main-category')}
            >
              {HeaderNavLinks?.MobileCategoryImage && (
                <MenuItemImgWrapper>
                  <StrapiImage
                    data={generateImgData(HeaderNavLinks?.MobileCategoryImage)}
                    noPaddingBottom
                    height={50}
                  />
                </MenuItemImgWrapper>
              )}
              {hasChildren ? (
                <>
                  <MenuItemTitle>{primaryLink?.MobileDisplayText ?? primaryLink?.DisplayText}</MenuItemTitle>
                  <StyledChevron />
                </>
              ) : (
                <StrapiLink data-testid={primaryLink?.testId} role="button" data={primaryLink}>
                  <MenuItemTitle>{primaryLink?.MobileDisplayText ?? primaryLink?.DisplayText}</MenuItemTitle>
                </StrapiLink>
              )}
            </MenuItemButton>
          </MenuItem>
        )
      })}
    </CategoriesMenuWrapper>
  )
}

CategoriesMenu.propTypes = {
  onDrawerClose: PropTypes.func,
  setSelectedIndex: PropTypes.func.isRequired,
}

export default CategoriesMenu
