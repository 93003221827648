import React from 'react'

import { useSelector } from 'react-redux'
import { Badge } from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import ShoppingCartActiveIcon from '@mui/icons-material/ShoppingCart'

import { ButtonLink } from '@templates/strapi-cms/content-types/Link'
import { getLineItemQuantity } from '@helpers/cart'
import { RightSideBtn } from '@components/header/mobile/Styled'

const Cart = () => {
  const cartQuantity = useSelector(state => getLineItemQuantity(state.order).cart)
  return (
    <RightSideBtn
      component={ButtonLink}
      edge="end"
      size="large"
      color="primary"
      data-testid="cartButton"
      trackingData={{ category: 'header', action: 'cart interaction', label: 'view full cart' }}
      data={{
        InternalUrl: '/cart',
        Title: 'View Cart',
      }}
    >
      <Badge color="primary" badgeContent={cartQuantity}>
        {typeof window !== 'undefined' && window.location.pathname === '/cart' ? (
          <ShoppingCartActiveIcon />
        ) : (
          <ShoppingCartIcon />
        )}
      </Badge>
    </RightSideBtn>
  )
}

export default Cart
