import { createContext, useContext } from 'react'

const StrapiContext = createContext({
  navigate: () => {},
  params: {},
  disabledCoupons: {},
  page: { id: '', PageType: '', Title: '', Route: '', SEO: {}, ContentTypes: [], BreadCrumbs: {}, isPLP: null },
})

export const StrapiContextProvider = StrapiContext.Provider

export const useStrapiContext = () => useContext(StrapiContext) || {}
