import { useState, useRef, useEffect, useCallback } from 'react'
import { func } from 'prop-types'
import useWindow from '@hooks/useWindow'
import { useLocation } from '@gatsbyjs/reach-router'

// eslint-disable-next-line import/prefer-default-export
export const usePage = ({ scrollEffect }) => {
  const [scrollDirection, setScrollDirection] = useState('UP')
  const { windowContext } = useWindow()
  const { pathname } = useLocation()
  const refScrollProperties = useRef()

  const scrollFunction = useCallback(
    e => {
      const scrollPosition = windowContext?.pageYOffset
      const lastScrollPosition = refScrollProperties?.current?.scrollPosition
      const direction = scrollPosition > lastScrollPosition && scrollPosition >= 0 ? 'DOWN' : 'UP'
      refScrollProperties.current = {
        scrollPosition,
        scrollDirection: direction,
      }
      if (
        direction !== scrollDirection &&
        (scrollPosition - lastScrollPosition > 10 || scrollPosition - lastScrollPosition < -10)
      ) {
        setScrollDirection(direction)
      }

      if (typeof scrollEffect === 'function') scrollEffect(refScrollProperties.current)
    },
    [scrollDirection, scrollEffect, windowContext],
  )

  useEffect(() => {
    windowContext?.addEventListener?.('scroll', scrollFunction)
    return () => {
      windowContext?.removeEventListener?.('scroll', scrollFunction)
    }
  }, [scrollFunction, windowContext])

  return {
    scrollDirection,
    scrollPosition: refScrollProperties?.current?.scrollPosition,
    pathname,
  }
}

usePage.propTypes = {
  scrollEffect: func,
}
